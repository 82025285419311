import { render, staticRenderFns } from "./IndexLazada.vue?vue&type=template&id=a39baeee&scoped=true&"
import script from "./IndexLazada.vue?vue&type=script&lang=js&"
export * from "./IndexLazada.vue?vue&type=script&lang=js&"
import style0 from "./IndexLazada.vue?vue&type=style&index=0&id=a39baeee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a39baeee",
  null
  
)

export default component.exports