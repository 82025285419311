<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <div v-if="!connected" class="w-75 mt-4 mx-auto">
    <b-row>
      <b-col cols="6" class="my-auto">
        <div>
          <h2>Kết nối kênh bán hàng Lazada</h2>
          <p class="text-primary">
            Kết nối hệ thống của IFashion và Lazada, giúp đẩy sản phẩm trực tiếp lên Lazada cũng như đồng bộ về tồn kho, đơn hàng.
          </p>
          <p>
            Kênh bán hàng Lazada Shop của bạn chưa được kích hoạt. Vui lòng kích hoạt để bắt đầu sử dụng.
          </p>
          <b-button
            variant="primary"
            class="btn-icon rounded"
            @click="handleConnect"
          >
            {{ $t('Kích hoạt kênh bán hàng') }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <img src="/banner_lazada.png" alt="Lazada">
      </b-col>
    </b-row>
  </div>
  <!-- <b-card v-else>
    <b-tabs
      content-class="pt-1"
    >
      <SyncProduct
        :list="syncProductList"
        channel="lazada"
      />
      <NotSyncProduct
        :list="notSyncProductList"
        channel="lazada"
      />
    </b-tabs>
  </b-card> -->
  <b-card
    v-else
    no-body
    class="coupon-card"
  >
    <b-card-header>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
        style="gap: 8px;"
      >
        <div>
          <h4>{{ $t('Danh sách sản phẩm đồng bộ') }}</h4>
        </div>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          to="add"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('Create new') }}
        </b-button>
      </div>
      <div class="filter-tab w-100">
        <div
          v-for="item in filterTabs"
          :key="item.id"
          :class="['filter-tab__item', {'active': filterSelected == item.id}]"
          @click="filterSelected = item.id"
        >
          {{ $t(item.label) }} ({{ productCounter(item.id) }})
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <!-- search input -->
      <div class="custom-search d-flex justify-content">
        <b-form-group style="width: 450px;">
          <div class="d-flex align-items-center mr-1">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="modelFilter.name" placeholder="Tìm kiếm theo mã nhâp hàng" />
            </b-input-group>
          </div>
        </b-form-group>
        <b-form-group style="width: 350px;">
          <div class="d-flex align-items-center mr-1">
            <flat-pickr
              v-model="modelFilter.date"
              class="form-control"
              :config="dateRangeConfig"
              :placeholder="$t('From - To date')"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="inventory-receives"
        class="custom-good-table"
        :columns="fields"
        :rows="items"
        styleClass="vgt-table"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Avatar + Name -->
          <span
            v-if="props.column.field === 'code'"
          >
            <router-link :to="`edit/${props.row.id}`">{{ props.row.code }}</router-link>
          </span>

          <span
            v-else-if="props.column.field === 'barcode'"
            style="max-width: 140px;"
          >
            {{ props.row.barcode }}
            <feather-icon
              class="cursor-pointer ml-1"
              icon="CopyIcon"
              @click="doCopy(props.row.barcode)"
            />
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              class="d-block"
              :variant="statusVariant(props.row.status)"
            >
              {{ props.row.status_label }}
            </b-badge>
          </span>

          <span
            v-else-if="props.column.field === 'price'"
            style="max-width: 140px;"
          >{{ unitFormatOriginal(props.row.price) }}</span>

          <!-- Column: Actions -->
          <span v-else-if="props.column.field === 'actions'">
            <router-link :to="`/combo/edit/${props.row.id}`">{{ $t('Detail') }}</router-link>
          </span>

          <!-- Column: Debt -->
          <span v-else-if="props.column.field === 'debt'" class="text-danger">
            {{ unitFormatOriginal(props.row.debt) }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pageOptions"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BButton,
  // BTabs,
  BCard,
  BFormGroup,
  BFormInput,
  BCardBody,
  BCardHeader,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
// import Request from '@/plugins/service/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import NotSyncProduct from './components/NotSyncProduct.vue'
// import SyncProduct from './components/SyncProduct.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    // BTabs,
    // NotSyncProduct,
    // SyncProduct,
    BCard,
    BFormGroup,
    BFormInput,
    BCardBody,
    BCardHeader,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    VueGoodTable,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      channelDetail: null,
      connected: false,
      channels: [
        {
          id: 1,
          name: 'Lazada',
          des: 'Connecting Buso and Lazada systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Lazada',
          img: require('@/assets/images/salesChannel/lazada.svg'),
          connected: false,
          link: 'channel/lazada',
          count: 0,
        },
        {
          id: 2,
          name: 'Shopee',
          des: 'Connecting Buso and Shopee systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Shopee',
          img: require('@/assets/images/salesChannel/shopee.svg'),
          connected: false,
          link: 'channel/shopee',
          count: 0,
        },
        {
          id: 3,
          name: 'Tiki',
          des: 'Connecting Buso and Tiki systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Tiki',
          img: require('@/assets/images/salesChannel/tiki.svg'),
          connected: false,
          link: 'channel/tiki',
          count: 0,
        },
        {
          id: 4,
          name: 'Facebook Live',
          des: 'Connecting Buso and Facebook Live systems, helping to synchronize inventory and prices, orders between the website system on Buso and your livestream on Facebook',
          img: require('@/assets/images/salesChannel/facebook.svg'),
          connected: false,
          link: '/setting-fb',
          count: 0,
        },
      ],
      notSyncProductList: [],
      syncProductList: [],
      fields: [
        {
          field: 'code',
          label: 'Code',
          width: '60px',
          sortable: false,
        },
        {
          field: 'created_at',
          label: 'Created at',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'supplier_name',
          label: 'Supplier',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'warehouse',
          label: 'Warehouse',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'price',
          label: 'Total price',
          type: 'number',
          tdClass: 'text-end',
          thClass: 'custom-th-class',
          sortable: false,
        },
        {
          field: 'debt',
          label: 'Còn nợ',
          type: 'number',
          tdClass: 'text-end',
          thClass: 'custom-th-class',
          sortable: false,
        },
      ],
      items: [
        {
          code: 'IR0707220330',
          created_at: '07/07/2022 15:30',
          supplier_name: 'B&T',
          warehouse: 'iFashion 51',
          price: 10000000,
          debt: 10000000,
        },
        {
          code: 'IR0707220332',
          created_at: '07/07/2022 15:32',
          supplier_name: 'B&T',
          warehouse: 'iFashion 51',
          price: 87000000,
          debt: 0,
        },
      ],
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
      modelFilter: {},
      perPage: 10,
      pageLength: 10,
      searchTerm: '',
      pageOptions: [5, 10, 20, 50, 100],
      filterTabs: [
        {
          id: null,
          label: 'All',
        },
        {
          id: true,
          label: 'Đồng bộ thành công',
        },
        {
          id: false,
          label: 'Lỗi đồng bộ',
        },
      ],
    }
  },
  computed: {
    miniList() {
      const temp = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.channels.length - 1; i++) {
        temp.push(this.channels[i])
      }
      // console.log(temp)
      return temp
    },
    filterItems() {
      if (this.filterSelected === 'all') return this.notSyncProductList
      return this.notSyncProductList.filter(item => item.is_active === this.filterSelected)
    },
  },
  mounted() {
    this.loadSiteInfo()
  },
  methods: {
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            if (res.data.data.access_token_lazada !== '' && res.data.data.access_token_lazada !== null && res.data.data.access_token_lazada !== undefined) {
              this.connected = true
            } else if (this.$route.query.code) {
              await this.handlerUpdate(this.$route.query.code)
            }
            this.loadSyncProduct()
            this.loadLazadaProduct(res.data.data.id)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChannelDetail(data) {
      this.channelDetail = data
    },
    async handlerSubmit(data) {
      if (data.id === 1) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/lazada/auth_partner?mode=sandbox&path=${window.location.origin}/channel/lazada`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.link
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
      if (data.id === 2) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/shopee/auth_partner?mode=production&path=${window.location.origin}/channel/shopee`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.link
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
      if (data.id === 4) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/facebook/get_oauth?type=1&redirect_uri=${window.location.origin}/channel/facebook-live`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.url
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },
    async handleConnect() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/lazada/auth_partner?mode=sandbox&path=${window.location.origin}/sales-channel/lazada`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            window.location.href = res.data.data.link
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerUpdate(code) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/lazada/get_access_token?code=${code}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const params = {
              // name: this.model.name,
              is_active: 1,
              access_token_lazada: res.data.data.access_token,
            }
            const resSite = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
              params,
            )
            if (resSite.status === 200) {
              if (resSite.data.status) {
                this.connected = true
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(resSite.data.error, ',')),
                  },
                })
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadLazadaProduct(siteID) {
      this.isShow = true
      try {
        await this.handleCategory(siteID)
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/lazada/get_item_list?site_id=${siteID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.products.map(async val => {
              const findIndex = this.syncProductList.findIndex(x => Number(x.lazada_id) === val.item_id)
              if (findIndex < 0) {
                const resDetail = await Request.get(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/lazada/get_item_detail/${val.item_id}?site_id=${siteID}`,
                )
                if (resDetail.status === 200) {
                  if (resDetail.data.status) {
                    const item = {
                      id: val.item_id,
                      avatar: resDetail.data.data.images[0],
                      name: resDetail.data.data.attributes.name,
                      sku: resDetail.data.data.skus[0].SellerSku,
                      category_id: resDetail.data.data.category_id,
                      // category_name: this.categories.find(x => x.category_id === resDetail.data.data.primary_category).name,
                      stock1: resDetail.data.data.skus[0].Available,
                      status: resDetail.data.data.status,
                    }
                    this.notSyncProductList.push(item)
                  }
                }
              }
            })
          }
        }
        this.isShow = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
    async loadSyncProduct() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&e_commerce=2`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.syncProductList = await this.getDataTranslation(res.items, true)
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleCategory(siteID) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/lazada/get_categories?site_id=${siteID}&language_code=vi_VN`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categories = res.data.data
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
//   async mounted() {},
//   methods: {},
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
